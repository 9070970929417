<template>
  <div
    class="modal"
    style="z-index: 1006 !important;"
    v-bind:class="{ 'is-active': showZoneListModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Zones</p>
        <button class="delete" aria-label="close" @click="toggleClose"></button>
      </header>
      <section class="modal-card-body">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Zone</th>
              <th>Min KM</th>
              <th>Max KM</th>
              <th>Region</th>
              <!-- <th>Environment</th> -->
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="zone in zoneList" :key="zone.key">
              <th>{{ zone.value }}</th>
              <td>{{ zone.min }}</td>
              <td>{{ zone.max }}</td>
              <td>{{ zone.region }}</td>
              <!-- <td>{{ zone.environment }}</td> -->
              <td>
                <button
                  class="button is-small is-success level-item is-rounded"
                  @click="openEditModal(zone)"
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  class="button is-small is-danger level-item is-rounded"
                  @click="openDeleteModal(zone)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>

          </tfoot>
        </table>
      </section>
      <footer class="modal-card-foot">
        <div class="columns is-gapless">
          <div class="column mx-1">
            <input type="text" v-model="newZone.value" placeholder="Zone" />
          </div>
          <div class="column mx-1">
            <input type="number" min="0" v-model="newZone.min" placeholder="Min KM" />
          </div>
          <div class="column mx-1">
            <input type="number" min="0" v-model="newZone.max" placeholder="Max KM" />
          </div>
          <div class="column mx-1">
            <button
              v-bind:class="
                isAdding ? addButtonLoading : addButtonNotLoading
              "
              @click="addZone"
            >
              Add
            </button>
          </div>
          <div class="column">
            <button
              class="button is-small level-item is-rounded"
              @click="toggleClose"
            >
              Close
            </button>
          </div>
        </div>
      </footer>
    </div>
    <EditZoneListModal
      v-if="showEditModal"
      @renderMap="toggleRender"
      :showEditModal="showEditModal"
      :selectedZone="selectedZone"
      @closeModal="closeEditModal"
    />
    <DeleteZoneListModal
      v-if="showDeleteModal"
      :showDeleteModal="showDeleteModal"
      :selectedZone="selectedZone"
      @closeModal="closeDeleteModal"
    />
  </div>
</template>
<script>
import { zonesInfoCollection } from '@/store/fireStore'
import store from '../store'

const environment = store.state.environment

export default {
  components: {
    EditZoneListModal: () => import('@/components/ZoneListEditModal'),
    DeleteZoneListModal: () => import('@/components/ZoneListDeleteModal')
  },
  data () {
    return {
      isAdding: false,
      addButtonLoading:
        'button is-small is-info level-item is-rounded is-loading',
      addButtonNotLoading:
        'button is-small is-info level-item is-rounded',
      showEditModal: false,
      showDeleteModal: false,
      selectedZone: null,
      newZone: {
        value: '',
        min: '',
        max: ''
      }
    }
  },
  props: {
    showZoneListModal: {
      type: Boolean
    }
  },
  methods: {
    toggleClose () {
      this.$emit('closeModal')
    },
    async toggleRender () {
      await this.$emit('renderMap')
    },
    async addZone () {
      const randomColor = () => {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)]
        }
        return color
      }
      this.isAdding = true
      await zonesInfoCollection
        .add({
          color: randomColor(),
          region: this.currentRegion.trim(),
          value: this.newZone.value,
          key: this.newZone.value,
          max: this.newZone.max,
          min: this.newZone.min,
          selected: false,
          environment
        })
        .then(() => {
          this.isAdding = false
          this.$notify({
            type: 'success',
            group: 'zones',
            title: 'Zone Added Successfully'
          })
          this.newZone = {
            value: '',
            min: '',
            max: ''
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            group: 'zones',
            title: 'There was an error, please try again'
          })
        })
    },
    openEditModal (zone) {
      this.showEditModal = true
      this.selectedZone = zone
    },
    closeEditModal () {
      this.showEditModal = false
      this.selectedZone = null
    },
    openDeleteModal (zone) {
      this.showDeleteModal = true
      this.selectedZone = zone
    },
    closeDeleteModal () {
      this.showDeleteModal = false
      this.selectedZone = null
    }
  },
  computed: {
    zoneList () {
      return this.$store.state.zoneInformations.filter((doc) => doc.region === store.state.region.trim())
    },
    currentRegion () {
      return store.state.region
    }
  }
}
</script>
<style>
</style>
